<script setup>
import { defineEmits} from 'vue'

const emit = defineEmits(['modal-close'])

const Close = ()=>{
    emit('modal-close')
}


</script>

<template>
    <div class="common-modal" @click="Close">
        <div class="common-modal-content" @click.stop>
            <button @click="Close" class="common-close-btn" type="button">×</button>
            <slot></slot>
        </div>
    </div>
</template>

<style lang="scss">

.common-modal{
    background:rgba(0,0,0,.8);
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
}
.common-modal-content{
    position: relative;
    width: 50%;
    max-width: 560px;
    min-width: 360px;
    padding: 2rem;

    background: rgb(235,235,235);
}

.common-close-btn{
    position: absolute;
    line-height: 0;
    font-size: 4rem;
    font-weight: 200;
    padding: 1rem .2rem 1.2rem 1.5rem;
    right: 0;
    top:-3rem;
    color: white;
}
</style>
