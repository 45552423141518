<script setup>
import useDate from '@/composables/useDate'
import { defineProps, computed, ref, reactive, defineEmits} from 'vue'
import { useStore } from 'vuex'
const store = useStore()
const props = defineProps({
    contents: Array,
    row: Object,
})

const emit = defineEmits(['select-days'])
const row = reactive(props.row)
const rowName = row.name


const contents = computed(
    {
        get: () => props.contents,
        set: () => props.contents,
    }
)
const placeName = (id) => {
    const place = store.state.places.find(e => e.id === id)
    return place.name

}

const start = ref(null)
const isSelected = ref(false)
const dateSelected = reactive([])
const isSelectIndex = reactive([])




const selectPdendingDay = (content) => {
    if (content.cols != 1 || content.is_active == 1) {
        alert('対象日をリセットしてから作業してください')
        return;
    }

       const obj = {
        content: content,
        rowData: row,
    }
    emit('select-days', obj)

}


const leaveCalendar = () => {
    if (isSelected.value) {
        start.value = null
       isSelected.value = false
        dateSelected.length = 0
        isSelectIndex.length = 0
        alert("選択範囲に問題があります。再度選択してください。")
        return false
    }
}


const slugName = (category_id) => {
    const tagetCategory = store.state.categories.find(e => e.id === category_id)
    return tagetCategory.slug
}

const selectChk = (index) => {
    if (isSelectIndex.includes(index)) {
        return true
    }
    return false
}




</script>




<template>
    <tr @mouseleave="leaveCalendar">
        <th class="race-body-header">{{ rowName }}</th>
        <td class="race-body " v-for="(content,index) in contents" :key="index"
        @click="selectPdendingDay(content)"
        :colspan="content.cols"
            :class="[selectChk(index) ? 'selected' : '', slugName(content.category_id), { 'active': isSelected, 'display-none': !content.is_visible,'not_final': !content.is_final,'is_active': content.is_active, 'pending-race': content.is_active == '2' }]">

            <template v-if=" content.is_active">
                <span class="text-sm">{{ placeName(content.place_id) }}</span>
            </template>
            <template v-else>
                <span class="text-gray-300 text-sm"> {{ useDate(content.date).day }}</span>
            </template>
        </td>
    </tr>
</template>


<style lang="scss">






.race-body.active.selected{
    background: blue;
}

.display-none{
   display: none !important;
}
.race-body.is_active.not_final{
    span::after{
        content:"× ";
        font-size:1.2em;
    }
}
.race-body.is_active.gen{
   background-color: rgb(254, 255, 180);
}

.pending-race{
    background: black !important;
    color: white !important;
}

</style>
