<script setup>
import useDate from '@/composables/useDate'
import { useStore } from 'vuex'
import { defineEmits, defineProps,inject,ref} from 'vue'
const emit = defineEmits(['get-monthdata','new-month','edit-type','reset-todayrace'])
const dayjs = inject("dayjs")

const props = defineProps({
    days: String,
    activeMonths: Array,
    updated_at: String,
    type: String
})
const store = useStore()
const editType = ref(props.type)

const getMonthData = (day) => {
    emit('get-monthdata', day)
}
const newMonth = (date) => {
    const last = dayjs(date)
    const next =last.add(1, "M").startOf("month").format("YYYY-MM-DD")
    emit('new-month',next)
}
const changeType = (type) => {
    editType.value = type
    emit('edit-type', type)
}
const todayRaceReset = () => {
    if(confirm(`公式サイトから本日のレースを再取得登録します`)){
        emit('reset-todayrace')
    }
}


</script>

<template>
    <div class="tag-wrap">
        <div>
            <button class="today-reset" @click="todayRaceReset">本日のレース一括再登録</button>
        </div>
        <div v-if=" store.state.pendingMode"  class="edit-type">
            <button class="edit-type-btn" @click="changeType('race')"  :class="{'active': editType =='race'}">通常モード</button>
            <button class="edit-type-btn"  @click="changeType('pendding')"  :class="{ 'active': editType == 'pendding'}">中止モード</button>
        </div>
          <div class="-h1-title-btn ml-auto">
            <p class="text-right mb-1">
                <span class="text-xs">最終更新日時：</span>{{ props.updated_at }}
            </p>
           <button
                v-for="month in props.activeMonths" :key="month"
                @click="getMonthData(month.date)"
                :class="[{ 'active': props.days == month.date }]"
                >
                {{ useDate(month.date).month }}月
                </button>
            <button class="create-month" @click="newMonth(props.activeMonths[props.activeMonths.length - 1].date)">新規</button>
        </div>
    </div>
</template>

<style scoped lang="scss">
.today-reset{
    background: red;
    padding: .5em 1em;
    width: auto;
    &:hover{
        background: black;
    }
}

.tag-wrap{
    display: flex;
    justify-content: start;
    align-items: end;
    margin-bottom: -2em;
}



button{
      background-color: rgb(154, 191, 212);
    color: rgb(255,255,255);
    padding: .2em 0;
    width: 60px;
    border-radius: .2em;
    margin-left: .5rem;
    &:hover{
        background-color: rgb(4, 1, 173);
    }
}
.active{
    background-color: rgb(4, 1, 173);
    &:hover{
        background-color: darken(rgb(4, 1, 173),15%);
    }
}
.create-month{
    background-color: rgb(180, 6, 6);
    margin-left: 1rem;
    &:hover{
         background-color: darken(rgb(180, 6, 6),10%);
    }
}
.edit-type-btn{
    width: 6em;
    font-size: .9em;
    &.active{
         background-color: rgb(4, 1, 173);
    }

}

</style>
